import '../css/About.css';
import DecorativeBar from './DecorativeBar';
import {useTranslation} from 'react-i18next';
import greetings_bubble from '../assets/earth-greetings.png'
import pin from '../assets/earth-pin.png'
import {useEffect, useState, useRef} from "react";
import {motion, useScroll, useTransform} from "motion/react";

const About = () => {
    const {t} = useTranslation();
    const targetRefText = useRef(null);
    const targetRefGlobe = useRef(null);
    const {scrollYProgress} = useScroll({
        target: targetRefText,
        offset: ["start 90%", "start 40%"],
    });

    const {scrollYProgress: scrollYProgressGlobe} = useScroll({
        target: targetRefGlobe,
        offset: ["start 70%", "start 40%"],
    });

    const opacity = useTransform(scrollYProgress, [0, 0.4], [0, 1]);
    const y = useTransform(scrollYProgress, [0, 0.4], ["-1vw", "0vw"]);

    const scaleX = useTransform(scrollYProgress, [0.45, 0.7], [0, 1]);

    const y_hint = useTransform(scrollYProgress, [0.75, 1], ['-1vw', '0vw']);
    const opacity_hint = useTransform(scrollYProgress, [0.75, 1], [0, 1]);


    const backgroundPosition = useTransform(scrollYProgressGlobe, [0, 1], ["50% 0", "15% 0"]);
    const backgroundSize = useTransform(scrollYProgressGlobe, [0.5, 1], ["150%", "200%"]);

    const opacity_pin = useTransform(scrollYProgressGlobe, [0.5, 1], [0, 1]);
    const x = useTransform(scrollYProgressGlobe, [0.5, 1], ["-10vw", "0vw"]);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <section id="about" className="about">
            {isMobile ? (
                <>
                    <motion.span
                        className="about-header"
                        initial={{x: "-100vw", opacity: 0}}
                        animate={{x: 0, opacity: 1}}
                        transition={{type: "spring", stiffness: 100, damping: 20, duration: 0.8}}
                    >
                        {t("about.about")}
                    </motion.span>
                    <div className="about-decorative-bar-title">
                        <motion.div style={{
                            width: '28%',
                            height: '2px',
                            color: 'white',
                            background: "linear-gradient(to right, #7c4bfa, #ff4387)",
                            transformOrigin: 'center',
                        }}/>
                    </div>

                    <div className="about-content">
                        <motion.span
                            className="about-hint-span"
                            initial={{x: "100vw", opacity: 0}}
                            animate={{x: 0, opacity: 1}}
                            transition={{type: "spring", stiffness: 100, damping: 20, duration: 0.8}}
                        >
                            {t("about.about_hint")}
                        </motion.span>

                        <motion.div
                            className="earth"
                            initial={{scale: 0.8, opacity: 0, backgroundSize: "150%", backgroundPosition: "50% 0"}}
                            animate={{scale: 1, opacity: 1, backgroundSize: "200%", backgroundPosition: "15% 0"}}
                            transition={{type: "spring", stiffness: 100, damping: 20, duration: 0.8, delay: 0.3}}
                        >
                            <motion.img
                                src={pin}
                                className="earth-pin"
                                initial={{scale: 0.5, opacity: 0}}
                                animate={{scale: 1, opacity: 1}}
                                transition={{type: "spring", stiffness: 100, damping: 20, duration: 0.8, delay: 0.7}}
                            />
                            <motion.img
                                src={greetings_bubble}
                                className="earth-greetings"
                                initial={{scale: 0.8, opacity: 0}}
                                animate={{scale: 1, opacity: 1}}
                                transition={{type: "spring", stiffness: 100, damping: 20, duration: 0.8, delay: 0.9}}
                            />
                        </motion.div>

                    </div>

                </>
            ) : (

                <>
                    <motion.span className='about-header' style={{opacity, y}}
                                 ref={targetRefText}>{t("about.about")}</motion.span>
                    <div className="about-decorative-bar-title">
                        <motion.div style={{
                            width: '28%',
                            height: '2px',
                            color: 'white',
                            background: "linear-gradient(to right, #7c4bfa, #ff4387)",
                            scaleX,
                            transformOrigin: 'center',
                        }}/>
                    </div>

                    <div className="about-content">
                        <motion.span className='about-hint-span'
                                     style={{y: y_hint, opacity: opacity_hint}}>{t("about.about_hint")}</motion.span>
                <motion.div ref={targetRefGlobe} style={{backgroundPosition, backgroundSize}} className="earth">
                    <motion.img src={pin} style={{opacity: opacity_pin}} className="earth-pin"/>
                    <motion.img src={greetings_bubble} style={{opacity: opacity_pin, x}} className="earth-greetings"/>
                    {!isMobile && (
                        <div className="about-decorative-bar">
                            <DecorativeBar type={2}/>
                        </div>
                    )}
                </motion.div>

            </div>
            </>


            )}
        </section>
    )
}

export default About;
