import '../css/ContactForm.css';
import FadeInOnScroll from "./FadeInOnScroll";
import { useTranslation } from 'react-i18next';
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import {Snackbar, Alert} from '@mui/material';

const ContactForm = () => {
    const { t } = useTranslation();
    const form = useRef();
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "info" });

    const [formData, setFormData] = useState({
        from_name: "",
        email: "",
        message: ""
    });

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();

        const { from_name, email, message } = formData;

        if (!from_name.trim() || !email.trim() || !message.trim()) {
            setSnackbar({
                open: true,
                message: t("contacts.error_empty_fields"),
                severity: "error"
            });
            return;
        }

        setLoading(true);

        emailjs
            .sendForm(
                "service_w3ayt6n",
                "template_gg4julp",
                form.current,
                "VbAKaioqfBm6WBIdy"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setEmailSent(true);
                    setSnackbar({
                        open: true,
                        message: t("contacts.success_message_sent"),
                        severity: "success"
                    });
                    setFormData({ from_name: "", email: "", message: "" });
                    setLoading(false);
                },
                (error) => {
                    console.log(error.text);
                    setEmailSent(false);
                    setSnackbar({
                        open: true,
                        message: t("contacts.error_message_failed"),
                        severity: "error"
                    });
                    setLoading(false);
                }
            );
    };

    return (
        <section id="contact-form" className="contact-form">
            <form
                className="contact-form"
                onSubmit={sendEmail}
                ref={form}
            >
                <FadeInOnScroll className="contact-form contact-form-fade" offsetPercentage={75}>
                    <input
                        id="name-input"
                        name="from_name"
                        type="text"
                        placeholder={t("contacts.name_form")}
                        value={formData.from_name}
                        onChange={handleInputChange}
                        disabled={loading}
                        className={loading ? "disabled-field" : ""}
                    />
                </FadeInOnScroll>

                <FadeInOnScroll className="contact-form contact-form-fade" offsetPercentage={75}>
                    <input
                        id="email-input"
                        name="email"
                        type="text"
                        placeholder={t("contacts.email_form")}
                        value={formData.email}
                        onChange={handleInputChange}
                        disabled={loading}
                        className={loading ? "disabled-field" : ""}
                    />
                </FadeInOnScroll>

                <FadeInOnScroll className="contact-form contact-form-fade" offsetPercentage={75}>
                    <textarea
                        id="message-input"
                        name="message"
                        placeholder={t("contacts.message_form")}
                        value={formData.message}
                        onChange={handleInputChange}
                        disabled={loading}
                        className={loading ? "disabled-field" : ""}
                    />
                </FadeInOnScroll>
                <FadeInOnScroll className="send-message-button" offsetPercentage={90}>
                    <button className="contact-form-button" disabled={loading}>
                        <span className='send-message-span'>
                            {loading ? t("contacts.sending_message") : t("contacts.send_message")}
                        </span>
                    </button>
                </FadeInOnScroll>
            </form>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </section>
    );
};

export default ContactForm;
