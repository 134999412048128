import { motion, useScroll, useTransform } from "motion/react";
import { useRef } from "react";

const FadeInOnScroll = ({ children, className, offsetPercentage = 60 }) => {
    const ref = useRef(null);

    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", `start ${offsetPercentage}%`],
    });

    const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);
    const scale = useTransform(scrollYProgress, [0, 1], [0.5, 1]);

    return (
        <motion.div
            ref={ref}
            className={className}
            style={{
                opacity,
                scale,
            }}
        >
            {children}
        </motion.div>
    );
};

export default FadeInOnScroll;