import joystick from "../assets/joystic.png";
import hat from "../assets/hat.png";
import house from "../assets/house.png";
import manufacturing from "../assets/manufacturing icon.png";
import shoe from "../assets/shoe.png";
import problemSolving from "../assets/problemSolving.png";
import innovation from "../assets/innovation.png";
import trainingPrograms from "../assets/trainingPrograms.png";
import prototyping from "../assets/prototyping.png";
import costReduction from "../assets/costReduction.png";
import remoteWork from "../assets/remoteWork.png";
import healthcare from "../assets/healthcare.png";
import smartCities from "../assets/smartCities.png";
import entertainment from "../assets/entertainment.png";
import disaster from "../assets/disaster.png";
import React from "react";
import Tab from "./Tab";
import {useTranslation} from "react-i18next";
const ARVRMRSection = () => {
    const { t } = useTranslation();

    const tabs = [
        { title: t("arvr.tab1"),
            content_list:[{content_title: t("arvr.problem_solving"), content_icon:problemSolving, content_description: t("arvr.problem_solving_text")},
                {content_title: t("arvr.collaborative_innovation"), content_icon:innovation, content_description: t("arvr.collaborative_innovation_text")},
                {content_title: t("arvr.training_programs"), content_icon:trainingPrograms, content_description: t("arvr.training_programs_text")},
                {content_title: t("arvr.prototyping"), content_icon:prototyping, content_description: t("arvr.prototyping_text")},
                {content_title: t("arvr.cost_reduction"), content_icon:costReduction, content_description: t("arvr.cost_reduction_text")}]},
        { title: t("arvr.tab2"),
            content_list:[{content_title: t("arvr.gaming"), content_icon:joystick, content_description: t("arvr.gaming_text")},
                {content_title: t("arvr.learning"), content_icon:hat, content_description: t("arvr.learning_text")},
                {content_title: t("arvr.manufacturing"), content_icon:manufacturing, content_description: t("arvr.manufacturing_text")},
                {content_title: t("arvr.real_estate"), content_icon:house, content_description: t("arvr.real_estate_text")},
                {content_title: t("arvr.retail"), content_icon:shoe, content_description: t("arvr.retail_text")}]},
        { title: t("arvr.tab3"),
            content_list:[{content_title: t("arvr.remote_work"), content_icon:remoteWork, content_description: t("arvr.remote_work_text")},
                {content_title: t("arvr.healthcare"), content_icon:healthcare, content_description: t("arvr.healthcare_text")},
                {content_title: t("arvr.smart_cities"), content_icon:smartCities, content_description: t("arvr.smart_cities_text")},
                {content_title: t("arvr.entertainment"), content_icon:entertainment, content_description: t("arvr.entertainment_text")},
                {content_title: t("arvr.disaster_response_planning"), content_icon:disaster, content_description: t("arvr.disaster_response_planning_text")}]},
    ];

    return (
        <section id="arvr-section" className="arvr-section" style={{position:"relative", width: "100%"}}>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "100%", position: "relative" }}>
                    <Tab defaultTab={1} tabs={tabs} />
                </div>
            </div>



        </section>


    )
}

export default ARVRMRSection;
