import vrGuy from '../assets/VR-guy.png';
import vrLines from '../assets/VR-guy-lines.png';
import vrBubblesen from '../assets/bubble-text-en.png';
import vrBubblespt from '../assets/bubble-text-pt.png';
import portal from '../assets/portal.webm';
import React from "react";
import ARVRMRSection from "./ARVRMRSection";
import "../css/ArVr.css"
import { useRef } from 'react';
import {useTranslation, Trans} from "react-i18next";
import {motion, useScroll, useTransform} from "motion/react";

const ARVRMR = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const targetRef = useRef(null);
    const targetRefPortal = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start 85%", "start 20%"],
    });

    const { scrollYProgress: portalProgress } = useScroll({
        target: targetRefPortal,
        offset: ["start end", "start 20%"],
    });

    const y = useTransform(scrollYProgress, [0, 0.4], ['-5vw', '1vw']);
    const fontSize = useTransform(scrollYProgress, [0, 0.4], ['10vw', '8vw']);

    const y_hint = useTransform(scrollYProgress, [0.45, 0.8], ['-1vw', '1vw']);
    const opacity_hint = useTransform(scrollYProgress, [0.45, 0.8], [0, 1]);

    const y_tabs = useTransform(scrollYProgress, [0.85, 1], ['-1vw', '1vw']);
    const opacity_tabs = useTransform(scrollYProgress, [0.85, 1], [0, 1]);

    const scale = useTransform(portalProgress, [0, 0.75], ['0%', '100%']);
    const scale_lines = useTransform(portalProgress, [0.65, 0.9], [0.5, 1]);
    const opacity_bubbles = useTransform(portalProgress, [0.95, 1], [0, 1]);

    return (
        <section id="arvr" className="arvr" style={{ position: 'relative', justifyContent: 'center', display: 'flex', zIndex:0}}>
            <div style={{position:"absolute", marginTop:"-5%", zIndex:1}}>
                <div style={{ position: 'relative', justifyContent: 'center', display: 'flex', zIndex: 0, marginTop:"-20%" }}>
                    <motion.img ref={targetRefPortal} src={vrGuy} style={{scale}} className="arvr-guy" alt="VR Guy" />
                    <motion.img src={vrLines} style={{scale: scale_lines}} className="arvr-guy-extras" alt="VR Lines" />
                    <motion.img src={currentLanguage === "en" ? vrBubblesen : vrBubblespt}  style={{opacity: opacity_bubbles}} className="arvr-guy-extras" alt="VR Bubbles" />
                    <video className='arvr-portal' autoPlay muted loop>
                        <source key={portal} src={portal} type="video/webm" />
                    </video>
                </div>
            </div>


            <div style={{position: 'relative', display:'flex', width: '100%', zIndex: '1', alignItems:'center', flexDirection:'column', marginTop:'8%'}}>
                <span className='text-outline-arvr'>{t("arvr.arvr")}</span>
                <motion.span className='arvr-header' ref={targetRef} style={{y, fontSize}}>{t("arvr.arvr")}</motion.span>
                <div className="ar-vr-outter-div">
                  <motion.span className="ar-vr-text" style={{y: y_hint, opacity: opacity_hint}}>
                      {t("arvr.arvr_hint")}
                  </motion.span>
                </div>
                <motion.div style={{y: y_tabs, opacity: opacity_tabs}}>
                <ARVRMRSection/>
                </motion.div>
            </div>
        </section>


    )
}

export default ARVRMR;
