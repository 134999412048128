import React, {useEffect, useRef, useState} from 'react';
import { useSwipeable } from "react-swipeable";
import DecorativeBar from "./DecorativeBar";
import "../css/Tab.css";
import {useTranslation} from "react-i18next";
import { motion, useScroll } from "motion/react";
import { easeCubicOut  } from 'd3-ease';



const Tab = ({ defaultTab, tabs }) => {
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(defaultTab);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);
    const [animationClass, setAnimationClass] = useState("");
    const [animation, setAnimation] = useState("");
    const [currentFrame, setCurrentFrame] = useState(0);
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["end start", "start end"]
    });

    const totalFrames = 225;

    useEffect(() => {
        const unsubscribe = scrollYProgress.onChange((progress) => {
            const easedProgress = easeCubicOut(progress);
            const interpolatedFrameIndex = Math.min(
                totalFrames,
                Math.round(easedProgress * totalFrames)
            );
            setCurrentFrame(interpolatedFrameIndex);
        });

        return () => {
            unsubscribe();
        };
    }, [scrollYProgress]);


    const frameSrc = require(`../assets/arvr_orb/frame_${String(currentFrame).padStart(3, "0")}.png`);

    const tabVariants = {
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 50 },
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleTabClick = (tabIndex) => {
        if (tabIndex !== activeTab) {
            setActiveTab(tabIndex);
            setAnimationClass(`tab${tabIndex + 1}`);
        }
    };

    const handleDotClick = (tabIndex) => {
        if (tabIndex !== activeTab) {
            setAnimation("swipe-out");
            setTimeout(() => {
                setActiveTab(tabIndex);
                setAnimation("swipe-in");
            }, 350);
            setAnimationClass(`tab${tabIndex + 1}`)
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => handleTabChange("next"),
        onSwipedRight: () => handleTabChange("prev"),
    });

    const handleTabChange = (direction) => {
        let newTab;

        if (direction === "next") {
            newTab = (activeTab + 1) % tabs.length;
            setAnimation("swipe-out");
            setTimeout(() => {
                setActiveTab(newTab);
                setAnimation("swipe-in");
            }, 350);
        } else if (direction === "prev") {
            newTab = (activeTab - 1 + tabs.length) % tabs.length;
            setAnimation("swipe-out");
            setTimeout(() => {
                setActiveTab(newTab);
                setAnimation("swipe-in");
            }, 350);
        }
        setAnimationClass(`tab${newTab + 1}`);
    };

    return (
        <>
            {isMobile && (
                <div
                    className="mobile-tabs"
                    {...swipeHandlers}
                    style={{ touchAction: "pan-y" }}
                >
                    <motion.img
                        ref={targetRef}
                        className={`mobile-background ${animationClass}`}
                        src={frameSrc}
                        alt={`Frame ${currentFrame}`}
                        />

                    <div
                        className="tab-buttons"
                        style={{
                            background: "transparent",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <div style={{ width: "100%", textAlign: "center", marginTop: "1.5rem" }}>
                            <span className={`mobile-tab-title tab-title ${animationClass}`}>
                                {tabs[activeTab].title}
                            </span>
                            <span className="swipe-indicator">{t("arvr.swipe")}</span>
                        </div>
                    </div>

                    <div className="tab-content">
                        {tabs[activeTab].content_list.map((item, index) => (
                            <div key={index} className="side-container">
                                <div className={`content ${animation}`}>
                                    <img
                                        className={`tab-item-icon ${animationClass}`}
                                        src={item.content_icon}
                                        alt={item.content_title}
                                    />
                                    <div className={`tab-item-title ${animationClass}`}>
                        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                            {item.content_title}
                        </span>
                                    </div>
                                    <div>
                                        <span>{item.content_description}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Dots Section */}
                    <div className="dots-container">
                        {tabs.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === activeTab ? "active-dot" : ""}`}
                                onClick={() => handleDotClick(index)}
                            />
                        ))}
                    </div>
                </div>

            )}
            {!isMobile && (
                <div className="tabs">
                    <div className="tab-buttons">
                        {tabs.map((tab, index) => (
                            <div key={index} className="tab">
                                <div style={{height: "100%", alignContent: "center"}}>
                                    <button
                                        key={index}
                                        onClick={() => handleTabClick(index)}
                                        className={`${index === activeTab ? 'active' : ''} tab-title ${animationClass}`}
                                    >
                                        {tab.title}
                                    </button>
                                </div>
                                <div className={`tab-decorative-bar ${animationClass}`}>
                                    {index === activeTab && <DecorativeBar />}
                                </div>

                            </div>
                        ))}
                    </div>
                    <div
                        className="tab-content"
                        style={{ display: "flex", alignItems: "center", width: "70%" }}
                    >
                        <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <motion.div
                                key={`first-${activeTab}`}
                                className="tab-width"
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={tabVariants}
                                transition={{ duration: 0.5, ease: "easeInOut" }}
                                style={{marginBottom: "1rem"}}
                            >
                                <motion.img
                                    src={tabs[activeTab].content_list[0].content_icon}
                                    alt={tabs[activeTab].content_list[0].content_title}
                                    className={`tab-item-icon ${animationClass}`}
                                    whileHover={{ scale: 1.1 }}
                                />
                                <motion.div
                                    className={`tab-item-title ${animationClass}`}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    <span className="tab-list-title">{tabs[activeTab].content_list[0].content_title}</span>
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.3 }}
                                >
                                    <span className="tab-list-text">{tabs[activeTab].content_list[0].content_description}</span>
                                </motion.div>
                            </motion.div>

                            <motion.div
                                key={activeTab}
                                className="tab-width"
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={tabVariants}
                                transition={{ duration: 0.5, ease: "easeInOut" }}
                            >
                                <motion.img
                                    src={tabs[activeTab].content_list[1].content_icon}
                                    alt={tabs[activeTab].content_list[1].content_title}
                                    className={`tab-item-icon ${animationClass}`}
                                    whileHover={{ scale: 1.1 }}
                                />
                                <motion.div
                                    className={`tab-item-title ${animationClass}`}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.3 }}
                                >
                                    <span className="tab-list-title">{tabs[activeTab].content_list[1].content_title}</span>
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    <span className="tab-list-text">{tabs[activeTab].content_list[1].content_description}</span>
                                </motion.div>
                            </motion.div>
                        </div>

                        <div style={{ flex: 1 }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <motion.img
                                    ref={targetRef}
                                    className={`tab-image ${animationClass}`}
                                    src={frameSrc}
                                    alt={`Frame ${currentFrame}`}
                                />

                                <motion.div
                                    key={activeTab}
                                    className="tab-width"
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    variants={tabVariants}
                                    transition={{ duration: 0.5, ease: "easeInOut" }}
                                    style={{ textAlign: "center", marginTop: "1rem" }}
                                >
                                    <motion.img
                                        src={tabs[activeTab].content_list[2].content_icon}
                                        alt={tabs[activeTab].content_list[2].content_title}
                                        className={`tab-item-icon ${animationClass}`}
                                        whileHover={{ scale: 1.1 }}
                                    />
                                    <motion.div
                                        className={`tab-item-title ${animationClass}`}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ delay: 0.3 }}
                                    >
                                        <span className="tab-list-title">{tabs[activeTab].content_list[2].content_title}</span>
                                    </motion.div>
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ delay: 0.4 }}
                                    >
                                        <span className="tab-list-text">{tabs[activeTab].content_list[2].content_description}</span>
                                    </motion.div>
                                </motion.div>
                            </div>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                                textAlign: "end",
                            }}
                        >
                            <motion.div
                                key={activeTab}
                                className="tab-width"
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={tabVariants}
                                transition={{ duration: 0.5, ease: "easeInOut" }}
                                style={{marginBottom: "1rem"}}
                            >
                                <motion.img
                                    src={tabs[activeTab].content_list[3].content_icon}
                                    alt={tabs[activeTab].content_list[3].content_title}
                                    className={`tab-item-icon ${animationClass}`}
                                    whileHover={{ scale: 1.1 }}
                                />
                                <motion.div
                                    className={`tab-item-title ${animationClass}`}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    <span className="tab-list-title">{tabs[activeTab].content_list[3].content_title}</span>
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.3 }}
                                >
                                    <span className="tab-list-text">{tabs[activeTab].content_list[3].content_description}</span>
                                </motion.div>
                            </motion.div>

                            <motion.div
                                key={`last-${activeTab}`}
                                className="tab-width"
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={tabVariants}
                                transition={{ duration: 0.5, ease: "easeInOut" }}
                            >
                                <motion.img
                                    src={tabs[activeTab].content_list[4].content_icon}
                                    alt={tabs[activeTab].content_list[4].content_title}
                                    className={`tab-item-icon ${animationClass}`}
                                    whileHover={{ scale: 1.1 }}
                                />
                                <motion.div
                                    className={`tab-item-title ${animationClass}`}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.3 }}
                                >
                                    <span className="tab-list-title">{tabs[activeTab].content_list[4].content_title}</span>
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    <span className="tab-list-text">{tabs[activeTab].content_list[4].content_description}</span>
                                </motion.div>
                            </motion.div>
                        </div>
                    </div>

                </div>
            )}


        </>
    );
};

export default Tab;
