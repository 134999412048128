import '../css/Contacts.css';
import DecorativeBar from './DecorativeBar';
import FadeInOnScroll from "./FadeInOnScroll";
import { useTranslation } from 'react-i18next';
import ContactForm from "./ContactForm";
import linkedInColored from "../assets/in.png"
import linkedIn from "../assets/inw.png"
import {useState} from "react";

const Contacts = () => {
    const {t} = useTranslation();

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        window.open("https://www.linkedin.com/company/atlantic-infinity", "_blank");
    };

    return (
        <section id="contacts" className="contacts">
            <FadeInOnScroll className="contacts-header">
                <span>{t("contacts.contacts")}</span>
            </FadeInOnScroll>

            <FadeInOnScroll className="contacts-description">
                <span>{t("contacts.description")}</span>
            </FadeInOnScroll>

            <br />

            <div
                className="contacts-decorative-bar"
            >
                <DecorativeBar />
            </div>

            <br />

            <FadeInOnScroll>
            <img
                src={isHovered ? linkedInColored : linkedIn}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
                className="contacts-image"
                alt="LinkedIn"
            />
            </FadeInOnScroll>

            <ContactForm />
        </section>
    )
}

export default Contacts;
