import {useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from './Modal';
import '../css/Footer.css'

const Footer =  () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const { t, i18n } = useTranslation();


    const disclaimerClick = () => {
        //setModalTitle(t('Disclaimer.title'));
        setModalTitle(t('Disclaimer.title'));
        const content = t('Disclaimer.content');
        setModalContent(content);
        openModal();
    };

    const policyClick = () => {
        setModalTitle(t('Policy.title'));
        const content = t("Policy.content");
        setModalContent(content);
        openModal();
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return(
        <div className='footer'>
            <div className='footer-content-container'>
            <div className='footer-content'>

                <div style={{ marginRight: "1%", cursor:"pointer"}} onClick={disclaimerClick}>
                    <h2>{t("Disclaimer.title")}</h2>
                </div>

                <div style={{ marginRight: "1%"}}>
                    <h2>|</h2>
                </div>

                <div style={{cursor:"pointer" }} onClick={policyClick}>
                    <h2>{t("Policy.title")}</h2>
                </div>

            </div>

            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    title={modalTitle}
                    content={modalContent}
                    closeModal={() => setIsModalOpen(false)}
                />
            )}
            </div>
        </div>
    );
};

export default Footer;

