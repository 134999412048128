import React from 'react';
import i18n from 'i18next';
import '../css/LanguageSwitcher.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const LanguageSwitcher = () => {
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const currentLanguage = i18n.language;

    const languages = [
        { code: 'en', name: 'English', flagClass: 'fi fi-gb' },
        { code: 'pt', name: 'Portuguese', flagClass: 'fi fi-pt' }
    ];

    return (
        <div className="language-switcher">
            {languages.map(({ code, name, flagClass }) => (
                <button
                    key={code}
                    onClick={() => changeLanguage(code)}
                    className={`language-button ${currentLanguage === code ? 'active' : ''}`}
                    aria-label={name}
                >
                    <span className={flagClass}></span>
                </button>
            ))}
        </div>
    );
};

export default LanguageSwitcher;
