import React, { useState, useEffect } from 'react';
import '../css/MobileMenu.css';
import LanguageSwitcher from "./LanguageSwitcher";
import logo_white from "../assets/logoW.png";
import {useTranslation} from "react-i18next";

const MobileMenu = () => {
    const {t} = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const [slideOutAnimation, setSlideOutAnimation] = useState(false);

    useEffect(() => {
        if (menuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [menuOpen]);

    const toggleMenu = () => {
            setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setSlideOutAnimation(true);
    };

    const handleAnimationEnd = () => {
        if (menuOpen && slideOutAnimation){
            setMenuOpen(!menuOpen);
            setSlideOutAnimation(false);
        }
    };

    const smoothScroll = (id,modifier) => {
        let  element = document.getElementById(id);
        if (element) {
            toggleMenu();
            window.scrollTo({
                top: element.offsetTop+modifier,
                behavior: 'smooth'
            });
        }
    };

    const menuItems = [
        {title:t("about.about"), id:"about", modifier:0},
        {title:t("development.development"), id:"development", modifier:0},
        {title:t("arvr.arvr"), id:"arvr", modifier:-550},
        {title:t("jobs.jobs"), id:"mid-section", modifier:500},
        {title:t("contacts.contacts"), id:"final-section", modifier:0}
    ]


    function handleTabClick(index) {
        smoothScroll(menuItems[index].id, menuItems[index].modifier);
    }

    return (
        <>
            <div className="menu-icon" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <div>
                <ul
                    className={`menu-options ${menuOpen ? "active" : ""} ${
                        slideOutAnimation ? "slide-out" : ""
                    }`}
                    onAnimationEnd={handleAnimationEnd}
                >
                    <li>
                        <div style={{ float: "left", height: "auto", width: "90%", marginBottom: "10%" }}>
                            <img src={logo_white} alt="Logo" style={{ width: "15%" }} />
                        </div>
                        <div className="top-right-buttons">
                            <div className="language-switcher-buttons">
                                <LanguageSwitcher />
                            </div>
                            <button className="close-icon" onClick={closeMenu}></button>
                        </div>
                    </li>
                    <li style={{ paddingTop: "16%"}}><a onClick={() => handleTabClick(0)}>{t("about.about")}</a></li>
                    <li><a onClick={() => handleTabClick(1)}>{t("development.development")}</a></li>
                    <li><a onClick={() => handleTabClick(2)}>{t("arvr.arvr")}</a></li>
                    <li><a onClick={() => handleTabClick(3)}>{t("jobs.jobs")}</a></li>
                    <li><a onClick={() => handleTabClick(4)}>{t("contacts.contacts")}</a></li>
                </ul>
            </div>
        </>
    );
};

export default MobileMenu;
