import React, {useState, useEffect, useRef} from 'react';
import '../css/MainContent.css';
import LanguageSwitcher from './LanguageSwitcher';
import Navbar from '../components/Navbar';
import Development from './Development';
import ARVRMR from './ARVRMR';
import About from './About';
import Jobs from './Jobs';
import Contacts from './Contacts';
import Footer from "./Footer";
import Starfield from "react-starfield";
import DecorativeBar from "./DecorativeBar";
import {useTranslation} from "react-i18next";
import header_background from '../assets/header-background.png';
import development_bg from "../assets/development_backgroud.png";
import {motion, useScroll, useMotionValue, animate, useTransform, useSpring} from "motion/react";


const MainContent = () => {
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const targetRef = useRef(null)
    const {scrollYProgress} = useScroll();
    const { scrollYProgress: menuProgress } = useScroll({
        target: targetRef,
        offset: ["start end", "start 85%"],
    });

    const menu_opacity = useTransform(menuProgress, [0, 1], [0, 1]);
    const menu_x = useSpring(useTransform(menuProgress, [0, 1], [-360, 0]), {
        type: "spring",
        stiffness: 150,
        damping: 50});


    const x = useMotionValue(0);

    useEffect(() => {
        const unsubscribe = scrollYProgress.onChange((latest) => {
            const newX = latest * (-window.innerWidth);

            animate(x, newX, {
                type: "tween",
                duration: 0.3
            });
        });

        return () => unsubscribe();
    }, [scrollYProgress, x]);

    const smoothScroll = (id, modifier) => {
        let element = document.getElementById(id);
        if (element) {
            const targetPosition = element.offsetTop + modifier;
            const startPosition = window.scrollY;
            const distance = targetPosition - startPosition;
            const duration = 1000;
            const startTime = performance.now();


            const easeOutQuad = (time, start, change, duration) => {
                time /= duration;
                return -change * time * (time - 2) + start;
            };

            const animation = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                const nextScrollPosition = easeOutQuad(elapsedTime, startPosition, distance, duration);

                window.scrollTo(0, nextScrollPosition);

                if (elapsedTime < duration) {
                    requestAnimationFrame(animation);
                } else {
                    window.scrollTo(0, targetPosition);
                }
            };

            requestAnimationFrame(animation);
        }
    };



    const menuItems = [
        {num_id: 0, title: t("about.about"), id: "about", modifier: 0},
        {num_id: 1, title: t("development.development"), id: "development", modifier: 0},
        {num_id: 2, title: t("arvr.arvr"), id: "arvr", modifier: -550},
        {num_id: 3, title: t("jobs.jobs"), id: "mid-section", modifier: 500},
        {num_id: 4, title: t("contacts.contacts"), id: "final-section", modifier: -150}
    ]


    function handleTabClick(index) {
        setActiveTab(index)
        smoothScroll(menuItems[index].id, menuItems[index].modifier);
    }

    return (
        <section id="main-content" className="main-content">
            <div className="first-section">
                <div className="image-wrapper">
                    <img src={header_background} alt="Header Background" className="background-image"/>
                </div>
                <Navbar/>

                <div className="language_selector">
                    <LanguageSwitcher/>
                </div>

                <div className="container">
                    <div className='main-title'>
                        <motion.span
                            className="title-span"
                            initial={{ x: "-100vw", opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 20,
                                duration: 0.8,
                            }}
                        >
                            {t("main_content.atlantic")}
                        </motion.span>
                        <motion.span
                            className="title-span"
                            initial={{ x: "-100vw", opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 20,
                                duration: 0.8,
                                delay: 0.4,
                            }}
                        >
                            {t("main_content.infinity")}
                        </motion.span>
                        <motion.span
                            className='title-hint'
                            initial={{ y: "-10vh", opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 20,
                                duration: 0.8,
                                delay: 1,
                            }}
                        >
                            {t("main_content.subtitle")}
                        </motion.span>
                    </div>
                    <div>
                        <motion.div className="menu" ref={targetRef} style={{opacity: menu_opacity, x: menu_x}}>
                            <div className="tab-buttons" style={{width: "auto"}}>
                                {menuItems.map((tab, index) => (
                                    <div key={tab.num_id}>
                                        <div>
                                            <button
                                                key={index}
                                                onClick={() => handleTabClick(index)}
                                                className={index === activeTab ? 'active' : ''}
                                            >
                                                {tab.title}
                                            </button>
                                        </div>
                                        <div style={{
                                            display: index === activeTab ? 'flex' : 'none',
                                            width: "100%",
                                            height: "auto",
                                            alignContent: "center",
                                            justifyContent: "center"
                                        }}>
                                            <DecorativeBar/>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </motion.div>
                        <span className="talk-to-us">
            </span>
                    </div>
                </div>
                <About/>
                <div className="development-bg-wrapper">
                    <div className="development-bg-container">
                        <motion.img style={{x}} className="development-bg" src={development_bg}
                                    alt="Development Background"/>
                        <motion.img style={{x}} className="development-bg" src={development_bg}
                                    alt="Development Background"/>
                    </div>
                </div>
                <Development/>
                <ARVRMR/>
            </div>
            <div id="mid-section" className="mid-section">
                <Jobs/>
            </div>
            <div id="final-section" className="final-section">
                <Contacts/>
                <Footer/>
            </div>


            <Starfield
                starCount={2500}
                starColor={[255, 255, 255]}
                speedFactor={0.05}
                backgroundColor="black"
            />
        </section>
    );
};

export default MainContent;
