import { useRef } from 'react';
import '../css/Development.css';
import DecorativeBar from './DecorativeBar';
import {useTranslation} from 'react-i18next';
import {motion, useScroll, useTransform} from "motion/react";

const Development = () => {
    const {t} = useTranslation();
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["end 90%", "start 20%"],
    });

    const y = useTransform(scrollYProgress, [0, 0.4], ['0vw', '6.5vw']);
    const fontSize = useTransform(scrollYProgress, [0, 0.4], ['10vw', '8vw']);

    const scaleX = useTransform(scrollYProgress, [0.45, 0.7], [0, 1]);

    const y_hint = useTransform(scrollYProgress, [0.75, 1], ['4vw', '6vw']);
    const opacity = useTransform(scrollYProgress, [0.75, 1], [0, 1]);

    return (
        <section id="development" className="development">
            <span className="text-outline">{t("development.development")}</span>
            <motion.span ref={targetRef} style={{y, fontSize}} className='development-header'>{t("development.development")}</motion.span>

            <motion.div style={{
                width: '50%',
                height: '2px',
                color: 'white',
                background: "linear-gradient(to right, #7c4bfa, #ff4387)",
                y,
                scaleX,
                transformOrigin: 'center',
            }} />

            <motion.div className='development-span-div' style={{y: y_hint, opacity}}>
                <span className='development-span'>{t("development.development_hint")}</span>
            </motion.div>
            {/**<button className="our-projects-button">
             <span className='our-projects-span'>{t("development.our_projects")}</span>
             </button>      */
            }
        </section>
    )
}

export default Development;
